import React from 'react';
import PageAnimation from '../../layout/PageAnimation';

// components
import SectionKarensHero from './SectionKarensHero';

const Karens = () => {
    return (
        <PageAnimation>
            <div className='w-full h-full md:p-20 pt-20'>
            <SectionKarensHero/>
        </div>
        </PageAnimation>
    )
}

export default Karens;