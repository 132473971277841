import {
  Routes,
  Route,
  useLocation 
} from "react-router-dom";
import './App.css';

import { AnimatePresence } from "framer-motion";

// Components
import MainLayout from "./layout/MainLayout";
import PageAnimation from "./layout/PageAnimation";
import PrivateRoute from "./context/Authentication/PrivateRoute";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Allies from "./pages/Allies/Allies";
import Participa from "./pages/Participa/Participa";
import News from "./pages/News/News";
import Panorama from "./pages/Panorama/Panorama";
import CameraGame from "./pages/CameraGame/CameraGame";
import Karens from "./pages/Karens/karens";


// Context
import { AuthProvider } from './context/Authentication/Auth';

function App() {
  const location = useLocation();

  return (
    <AuthProvider>
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route element={<MainLayout />}>
            <Route path="/" element={<PageAnimation><Home /></PageAnimation>} />
            <Route path="/panorama">
              <Route path=":panoId" element={<PageAnimation><Panorama /></PageAnimation>} />
            </Route>
            <Route path="/minijuegos/fotos" element={<PageAnimation><CameraGame /></PageAnimation>} />
            <Route path="/elreinoalado" element={<PageAnimation><About /></PageAnimation>} />
            <Route path="/noticias" element={<PageAnimation><News/></PageAnimation>} />
            <Route path="/aliados" element={<PageAnimation><Allies /></PageAnimation>} />
            <Route path="/participa" element={<PageAnimation><Participa/></PageAnimation>} />
            <Route path="/autenticacion" element={<PageAnimation><div>Login</div></PageAnimation>} />
            <Route path="/karenspizza" element={<PageAnimation><Karens/></PageAnimation>} />
            <Route
              path="/protected"
              element={
                <PrivateRoute>
                  <div>Página privada</div>
                </PrivateRoute>
              }
            />
          </Route>
        </Routes>
      </AnimatePresence>
    </AuthProvider>
  );
}

export default App;
